// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliate-disclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-write-for-us-js": () => import("./../../../src/pages/write-for-us.js" /* webpackChunkName: "component---src-pages-write-for-us-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

